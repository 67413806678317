import React, { FunctionComponent, useEffect, useState } from 'react'
import SEO                                               from '../components/seo'
import Layout                       from '../containers/layout'
import { toPlainText }              from '../utilities/helpers'
import WebinarOverview              from '../components/webinars/WebinarOverview'
import { Webinar }                  from '@open-law/open-law-shared'
import { graphql }                  from 'gatsby'

export const query = graphql`
    query WebinarOverviewQuery($id: String!) {
        webinar: sanityWebinar(id: { eq: $id }) {
            id
            title
            slug {
                current
            }
            authors {
                id
                bio
                contactEmail
                image {
                    asset {
                        url
                    }
                }
                jobTitle
                name
            }
            parts {
                _rawMaterials(resolveReferences: { maxDepth: 20})
                partBlurb
                slug {
                    current
                }
                title
                video {
                    videoChapters {
                        _key
                        timeReference
                        chapterDescription
                    }
                    videoUrl
                    _key
                }
            }
        }
    }
`

interface WebinarOverviewTemplateProps {
    pageContext: {
        partIndex: number
        id: string
    }
    data: {
        webinar: Webinar
    }

}

const WebinarOverviewTemplate: FunctionComponent<WebinarOverviewTemplateProps> = (props) => {
    const [webinar, setWebinar] = useState<Webinar>()
    const [partIndex, setPartIndex] = useState<number>()

    useEffect(() => {
        setWebinar({...props.data.webinar})
    }, [props.data.webinar])

    useEffect(() => {
        setPartIndex(Number(props.pageContext.partIndex))
    }, [props.pageContext.partIndex])

    return (
        <Layout textWhite={false}>
{/*
            {
                webinar && (
                    <SEO
                        title={webinar.title || 'Untitled'}
                        description={toPlainText(webinar.blurb)}
                        image={webinar.mainImage}
                    />
                )
            }
*/}

            {
                <WebinarOverview partIndex={partIndex} webinar={webinar} />
            }
        </Layout>
    )
}

export default WebinarOverviewTemplate
