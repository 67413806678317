import React, {FunctionComponent, Ref} from 'react'
import ReactPlayer                     from 'react-player'

interface YouTubeEmbedProps {
    url: string
    playerRef?: Ref<any>
    playing?: boolean
}

const YouTubeEmbed: FunctionComponent<YouTubeEmbedProps> = (props) => {


    const {url, playing, playerRef} = props
    // console.log(props)
    if (!url) {
        return null
    }
    return (
        <section className="video-wrapper">
            <ReactPlayer
                className={'video-embed'}
                playing={playing}
                controls={true}
                ref={playerRef}
                url={url}
                width={'100%'}
                height={'100%'}
             />
        </section>
    )
}

export default YouTubeEmbed
